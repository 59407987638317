
import { Task } from "@/store/modules/task.store";
import { Component, Vue, Prop } from "vue-property-decorator";

interface TaskCategoryGroup {
    [key: string]: Task[];
}

@Component({
    components: {
        TaskPart: () => import("./task.vue"),
    },
})
export default class PageSellerPartTaskGroup extends Vue {
    @Prop({ required: true }) readonly taskGroup!: TaskCategoryGroup | Task[];

    get isTaskCateoryGroup(): boolean {
        return typeof this.taskGroup === "object" && !Array.isArray(this.taskGroup);
    }

    get localTaskGroups(): { [key: string]: Task[] } {
        if (this.isTaskCateoryGroup) {
            return this.taskGroup as { [key: string]: Task[] };
        }

        return { "": this.taskGroup as Task[] };
    }
}
